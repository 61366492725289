import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import { Container } from 'reactstrap'

import Layout from '../components/Layouts'

const AllPages = ({ data }) =>
	<Layout>
		<Container>
			<h1>Pages</h1>
			{data.allWordpressPage.edges.map(({ node }) => (
				<Link to={node.full_slug} key={node.id}>
					<h3>{node.title}</h3>
					<div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
				</Link>
			))}
		</Container>
	</Layout>

AllPages.propTypes = {
	data: PropTypes.object.isRequired,
	edges: PropTypes.array,
}

export default AllPages

export const pageQuery = graphql`
	query Pages {
		allWordpressPage(sort: { fields: [date] }) {
			edges {
				node {
					id
					title
					excerpt
					full_slug
				}
			}
		}
	}
`